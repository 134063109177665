
import { Component, Vue } from 'vue-property-decorator'
import { DetailInfo } from '@/types/guideComment'

@Component({
  name: 'GuideCommentList'
})
export default class GuideCommentList extends Vue {
  private searchInfo = {
    shield: '',
    dataRange: []
  }

  private loading = false
  private tableData: DetailInfo[] = []

  private page = 1
  private size = 10
  private total = 0

  created (): void {
    this.getList()
  }

  // 查询
  onQuery () {
    this.page = 1
    this.getList()
  }

  // 表格
  getList () {
    this.loading = true
    const info = this.searchInfo.dataRange ? {
      startTime: this.searchInfo.dataRange[0],
      endTime: this.searchInfo.dataRange[1]
    } : {}
    this.$axios
      .get<{ total: number; list: DetailInfo[] }>(
        this.$apis.guideComment.selectAdminEvaluateByPage,
        {
          objectId: this.$route.query.id,
          page: this.page,
          size: this.size,
          shield: this.searchInfo.shield,
          ...info
        }
      )
      .then((res) => {
        if (res) {
          this.tableData = res.list || []
          this.total = res.total || 0
        }
      })
      .finally(() => {
        this.loading = false
      })
  }

  // 状态修改
  onStatus (info: {id: string; shield: string }) {
    let message = ''
    let resultMessge = ''
    if (info.shield === '0') {
      message = '确认显示此条评价吗, 是否继续?'
      resultMessge = '显示成功'
    } else {
      message = '确认屏蔽此条评价吗, 是否继续?'
      resultMessge = '屏蔽成功'
    }
    this.$confirm(message, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.guideComment.shieldEvaluate, { id: info.id }).then(() => {
        this.$message.success(resultMessge)
        this.onQuery()
      })
    })
  }

  // 置顶
  onTop (info: {id: string; top: string }) {
    let message = ''
    let resultMessge = ''
    if (info.top === '1') {
      message = '确认取消此条评价置顶吗, 是否继续?'
      resultMessge = '取消置顶成功'
    } else {
      message = '确认置顶将此条评价吗, 是否继续?'
      resultMessge = '置顶成功'
    }
    this.$confirm(message, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.guideComment.topEvaluate, { id: info.id }).then(() => {
        this.$message.success(resultMessge)
        this.onQuery()
      })
    })
  }

  // 删除
  onDelete (id: string) {
    this.$confirm('确认删除将此条评价吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.guideComment.deleteEvaluate, { id }).then(() => {
        this.$message.success('删除成功')
        this.onQuery()
      })
    })
  }
}
